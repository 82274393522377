<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Blog - Tagy - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
        label="Název"
          :validate-status="nameError() ? 'error' : ''"
          :help="nameError() || ''"
        >
          <a-input placeholder="Název" v-decorator="['bte_name', {rules: [{max: 50, message: 'Název nemůže být delší než 50 znaků'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/blog/tag',
          title: 'Seznam',
        },
      ],
      form: this.$form.createForm(this),
      item: {
        tags: [],
      },
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('blogTag/post', this.item.tags.filter(x => x.bte_name.trim().length > 0))
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleChange(oldLanguage) {
      const foundObj = { tag: false }
      const newObj = {
        tag: {
          lge_id: oldLanguage,
          bte_name: this.form.getFieldValue('bte_name'),
        },
      }
      this.item.tags.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.tags[key] = newObj.tag
          foundObj.tag = true
          return true
        }
      })
      if (!foundObj.tag) {
        this.item.tags.push(newObj.tag)
      }
    },
    nameError() {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('bte_name') && getFieldError('bte_name')
    },
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = this.item.tags.find(x => x.lge_id === newValue)
        this.form.setFieldsValue({
          bte_name: foundObj === undefined ? '' : foundObj.bte_name,
        })
        this.form.validateFields()
      }
    },
  },
}
</script>
